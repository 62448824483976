<template>
  <div>
    <v-card>
      <v-img
        :src="`uploads/${galleryCoverImg}`"
        gradient="to top right, rgba(255,255,225,.5), rgba(25,32,72,.7)"
        height="300px"
      >
        <v-row justify="center" class="fill-height display-1">
          <v-col cols="12" align-self="center" class="text-center mt-10">
            <h1 class="galtitle" :style="{ color: titleFontColor }">GALLERY</h1>
          </v-col>

          <v-col v-if="isLoggedIn" cols="12" class="text-end pr-md-10 pr-sm-3">
            <input
              id="galleryCoverImage"
              hidden
              type="file"
              accept="image/*"
              @change="handleGalleryCoverImage"
            />
            <label for="galleryCoverImage">
              <v-icon title="Change Image" class="rounded-circle secondary pa-3"
                >mdi-camera</v-icon
              >
            </label>
          </v-col>
        </v-row>
      </v-img>
    </v-card>

    <v-dialog
      v-model="dialogadd"
      v-if="isLoggedIn"
      transition="dialog-bottom-transition"
      max-width="600"
    >
      <template v-slot:activator="{ on, attrs }">
        <p class="secondary--text text-center" v-if="!emptyTab">
          <v-btn v-bind="attrs" v-on="on" class="ma-5" fab color="secondary">
            <v-icon color="primary">mdi-plus</v-icon></v-btn
          >
          Add New Pix
        </p>
        <p class="secondary--text text-center py-2" v-else>
          CREATE CATEGORY TABS
        </p>
      </template>

      <v-card class="px-4">
        <v-select
          :items="tabs"
          item-text="title"
          item-value="title"
          label="choose title"
          v-model="selectTitle"
        ></v-select>
        <input
          id="galleryImages"
          type="file"
          accept="image/*"
          @change="addImageChange"
        />
        <v-card-actions class="justify-end">
          <v-btn text @click="addImageHandler">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-tabs v-model="tab" show-arrows center-active color="accent" right>
      <v-spacer></v-spacer>
      <v-dialog
        v-model="dialog"
        :retain-focus="true"
        v-if="isLoggedIn"
        transition="dialog-bottom-transition"
        max-width="600"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            title="Add New Tab"
            v-bind="attrs"
            v-on="on"
            fab
            small
            color="secondary"
            @click="newForm"
          >
            <v-icon color="primary">mdi-plus</v-icon></v-btn
          >
        </template>
        <v-card class="px-4">
          <v-text-field label="title" v-model="eventTitle"></v-text-field>
          <v-card-actions class="justify-end">
            <v-btn text @click="addEventTitleHandler" v-if="type">Save</v-btn>
            <v-btn text @click="editEventTitleHandler" v-if="!type"
              >Save Changes</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-tab>All</v-tab>
      <v-tab v-for="tab in tabs" :key="tab.title">
        <v-btn text x-small v-if="isLoggedIn" @click="editForm(tab)">
          <v-icon size="15px">mdi-pencil</v-icon></v-btn
        >
        {{ tab.title }}
        <v-btn
          text
          x-small
          v-if="isLoggedIn"
          @click="deleteEventTitleHandler(tab.id)"
        >
          <v-icon size="15px">mdi-close</v-icon></v-btn
        >
      </v-tab>

      <v-tab-item>
        <v-container :style="{ backgroundColor: bgColor }" fluid>
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="4"
              v-for="(gallery, id) in getImages()"
              :key="id"
              @click="index = id"
            >
              <v-img
                height="400"
                width="400"
                :src="`/uploads/${gallery.src}`"
                lazy-src="camera.png"
                aspect-ratio="1"
                class="mx-auto text-center accent lighten-2"
              >
                <template v-if="isLoggedIn" v-slot:default>
                  <p class="text-end">
                    <v-btn
                      @click.stop="deleteImageHandler(gallery)"
                      fab
                      color="secondary"
                      x-small
                      ><v-icon color="primary">mdi-close</v-icon></v-btn
                    >
                  </p>
                </template>
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="accent lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>

      <v-tab-item v-for="tab in tabs" :key="tab.id">
        <v-container :style="{ backgroundColor: bgColor }" fluid>
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="4"
              v-for="gallery in getImages(tab)"
              :key="gallery.id"
            >
              <v-img
                height="400"
                width="400"
                :src="`/uploads/${gallery.src}`"
                lazy-src="camera.png"
                aspect-ratio="1"
                class="mx-auto text-center accent lighten-2"
              >
                <template v-if="isLoggedIn" v-slot:default>
                  <p class="text-end">
                    <v-btn fab color="secondary" x-small
                      ><v-icon
                        @click="deleteImageHandler(gallery)"
                        color="primary"
                        >mdi-close</v-icon
                      ></v-btn
                    >
                  </p>
                </template>
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="accent lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
    </v-tabs>
    <CoolLightBox :items="getImages()" :index="index" @close="index = null">
    </CoolLightBox>
    <v-snackbar v-model="snackbar" bottom :color="snackColor">
      <p class="secondary--text">{{ snackMsg }}</p>
      <template v-slot:action="{ attrs }">
        <v-btn dark v-bind="attrs" @click="snackbar = false">
          <v-icon color="secondary">mdi-close</v-icon>
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { apiClient } from "@/services";
import { snackMixin } from "@/mixins";

export default {
  mixins: [snackMixin],
  props: {
    titleFontColor: {
      type: String,
    },
    subtitleFontColor: {
      type: String,
    },
    bgColor: {
      type: String,
    },
  },
  data() {
    return {
      items: [{ src: "fas3.jpg" }, { src: "fas4.jpg" }],
      index: null,
      dialogadd: null,
      selectTitle: "",
      dialog: null,
      galleryCoverImg: "",
      eventTitle: "",
      tab: null,
      tabs: [{ title: "all", id: 0 }],
      type: true,
      image: null,
      gallerys: [],
    };
  },
  computed: {
    ...mapGetters(["isLoggedIn"]),
    emptyTab() {
      return !this.tabs.length;
    },
  },
  created() {
    apiClient
      .get("/pages/load-gallery-image")
      .then((res) => {
        this.galleryCoverImg = res.data.galleryCoverImg;
      })
      .catch((error) => {
        if (error.response) {
          return this.displayMsg(error.response.data.errorMessage, "error");
        }
        this.displayMsg("something went wrong", "error");
      });
    this.getEventTitleHandler();
    this.getAllImages();
  },

  methods: {
    getImages(tab) {
      return tab
        ? this.gallerys.filter((img) => img.title == tab.title)
        : this.gallerys;
    },

    async handleGalleryCoverImage(event) {
      let data = new FormData();
      data.append("name", "file");
      data.append("file", event.target.files[0]);
      try {
        const res = await apiClient.put("/pages/change-gallery-coverImg", data);
        this.galleryCoverImg = res.data.galleryCoverImg;
        this.displayMsg("Updated successfully", "success");
      } catch (error) {
        this.displayMsg(error.message, "error");
      }
    },

    async addEventTitleHandler() {
      if (!this.eventTitle)
        return this.displayMsg("Title field is requierror", "error");
      try {
        const res = await apiClient.post("/events", { title: this.eventTitle });

        this.tabs.push(res.data.event);
        this.eventTitle = "";
        this.dialog = false;
        this.displayMsg("Updated successfully", "success");
      } catch (error) {
        this.displayMsg(error.message, "error");
      }
    },

    async getEventTitleHandler() {
      try {
        const res = await apiClient.get("/events");
        this.tabs = res.data.events;
      } catch (error) {
        if (error.response) {
          return this.displayMsg(error.response.data.errorMessage, "error");
        }
        this.displayMsg("something went wrong", "error");
      }
    },

    async deleteEventTitleHandler(id) {
      try {
        await apiClient.delete(`/events/${id}`);
        this.tabs = this.tabs.filter((tab) => tab.id !== id);
        this.displayMsg("deleted", "success");
      } catch (error) {
        this.displayMsg(error.message, "error");
      }
    },

    newForm() {
      this.dialog = true;
      this.type = true;
      this.eventTitle = "";
    },

    editForm(tab) {
      this.dialog = true;
      this.type = false;
      this.eventTitle = tab.title;
      this.tab = tab;
    },

    async editEventTitleHandler() {
      // this.eventTitle = this.tab.title
      this.dialog = true;
      try {
        const { data } = await apiClient.put(`/events/${this.tab.id}`, {
          title: this.eventTitle,
        });
        this.tabs = this.tabs.filter((tab) => tab.id !== this.tab.id);
        this.tabs.push(data.event);
        this.dialog = false;
      } catch (error) {
        this.displayMsg("Something went wrong", "error");
      }
    },

    async getAllImages() {
      try {
        const { data } = await apiClient.get("/images");
        this.gallerys = data.images;
        this.dialog = false;
      } catch (error) {
        if (error.response) {
          return this.displayMsg(error.response.data.errorMessage, "error");
        }
        this.displayMsg("something went wrong", "error");
      }
    },

    addImageChange(event) {
      this.image = event.target.files[0];
    },
    async addImageHandler() {
      if (!this.selectTitle || !this.image) {
        return this.displayMsg("All fields requierror", "error");
      }
      let data = new FormData();
      data.append("name", "file");
      data.append("file", this.image);
      data.append("title", this.selectTitle);
      try {
        const res = await apiClient.post("/images", data);
        this.gallerys.push(res.data.image);
        this.displayMsg("Updated successfully", "success");
        this.dialogadd = false;
      } catch (error) {
        if (error.response) {
          return this.displayMsg(error.response.data.errorMessage, "error");
        }
        this.displayMsg("something went wrong", "error");
      }
    },

    async deleteImageHandler(gallery) {
      try {
        await apiClient.delete(`/images/${gallery.id}`);
        this.gallerys = this.gallerys.filter((g) => g.id !== gallery.id);
      } catch (error) {
        return this.displayMsg("something went wrong", "error");
      }
    },
  },
};
</script>

<style scoped>
.galtitle {
  font-size: 75px;
}

@media screen and (max-width: 600px) {
  .galtitle {
    font-size: 50px;
  }
}
</style>
